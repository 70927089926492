import React from 'react'

// import image
import HeroImg from '../assets/img/web4.png'

const Hero = () => {
    return (
        <section id='home' className=' lg:h-[85vh] flex items-center
         bg-primary lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden'>

            <div className=' container mx-auto h-full '>
                <div className=' flex items-center h-full pt-8'>
                    {/* left slide */}
                    <div className=' flex-1 flex flex-col items-center lg:items-start'
                        data-aos='fade-down'>
                        <p className='text-amber-300'> Hey, I'm Volodymyr 👏</p>
                        <h1 className=' text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-5xl lg:leading-[1.2]
                        font-bold md:tracking-[-2px]'> I Build & Design <br /> Web Interfaces.</h1>
                        <p className=' pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-lg text-center lg:text-left'>Your wish, my realization.</p>
                        <button className='btn btn-md bg-amber-400 hover:bg-amber-200 hover:text-black'>Work with me</button>

                    </div>

                    {/* right slide */}
                    <div className=' hidden lg:flex flex-1 justify-end items-end h-full'
                        data-aos='fade-up'>
                        <img src={HeroImg} alt='' />
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Hero;