import React from 'react'

// impport foto about
import Foto from '../assets/img/about-1.jpg'

const About = () => {
    return (
        <section id='about' className='section bg-secondary'>
            <div className=' container mx-auto'>
                <div className=' flex flex-col xl:flex-row gap-24'>
                    <img className=' object-cover h-full w-[566px] md: mx-auto lg:mx-0 rounded-2xl' src={Foto} alt=''
                        data-aos='fade-up'
                        data-aos-offset='300'
                    />
                    <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
                        <div data-aos='fade-down' className='flexv flex-col'>
                            <h2 className=' text-3xl lg:text-4xl font-medium lg:font-extralight mb-3 before:content-about
                             relative before:absolute before:opacity-40 before:-top-[-2rem] before:hidden before:lg:block'>
                                Volodymyr Kopaigorodskyi
                            </h2>
                            <p className=' mb-4 text-amber-300'>Web developer</p>
                            <hr className='mb-8 opacity-5'></hr>
                            <p className='mb-10'>
                                Hello.
                            </p>
                            <p className='mb-10'>
                                I am a developer from Ukraine. More than 3 years of commercial experience in interface development and web animation.
                            </p>
                            <p className='mb-10'>
                                I can offer my skills and provide full professional services when it comes to web development and my main skills are: HTML and CSS, Javascript jQuery and WordPress.
                            </p>
                            <p className='mb-10'>
                                Can create perfect and fully responsive pages from any design format like PSD, Figma, Adobe XD, etc.
                            </p>
                        </div>
                        <button className='btn btn-md bg-amber-400 hover:bg-amber-200 hover:text-black' data-aos='fade-up'>Contact me</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;