import React, { useEffect, useState } from 'react'

//import components

import Nav from '../components/Nav'
import NavMobile from '../components/NavMobile'
import Socials from '../components/Socials'

const Header = () => {

    const [bg, setBg] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            return window.scrollY > 50 ? setBg(true) : setBg(false);
        })
    })

    return (
        <header className={`${bg ? 'bg-tertiary h-20' : 'h-24'}
        flex items-center fixed top-0 w-full text-white z-10 transition-all duration-300`}
            data-aos='fade-down'
            data-aos-duration='2000'
            data-aos-delay='900'>
            <div className='container mx-auto h-full flex
            items-center justify-between text-amber-400 text-3xl lg:text-4xl font-medium'>
                Portfolio
                {/** nav */}
                <div className='hidden lg:block'>
                    <Nav />
                </div>
                {/* socials */}
                <div className='hidden lg:block'>
                    <Socials />
                </div>
                {/* NavMobile */}
                <div className='lg:hidden'>
                    <NavMobile />
                </div>
            </div>
        </header>
    )
}

export default Header;